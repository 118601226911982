
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Sydney - Australia | Kiwi & Cacahuate" description="A quick Easter trip to Sydney for Ivette's first holiday week. Opera house's, bridges and Sun." url="https://kiwicacahuate.com/sydney/" image="https://kiwicacahuate.com/sydney/sydney.jpg" imageWidth="3000" imageHeight="2131" />

    <div className=''>
      <div className="story complete" data-photoset="sydney">

        <div className="photoset-section">

          <div className="photoset-row">
            <div className="photoset-item ten">
              <img data-position="0" data-layzr="sydney/thumbnail/1.jpg" data-layzr-retina="sydney/thumbnail/1-2x.jpg" data-layzr-webp="sydney/thumbnail/1.webp" data-layzr-retina-webp="sydney/thumbnail/1-2x.webp" data-name="1" />
              <picture data-name="4">
                <source srcSet="/sydney/thumbnail/1.webp, /sydney/thumbnail/1-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/1.jpg, /sydney/thumbnail/1-2x.jpg 2x" />
                <img src="/sydney/thumbnail/1.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-intro">
            <h4>Potts Point to Opera house</h4>
            <p>On the first day, we walk from our Airbnb in Potts Point around the waterfront to the Opera house.<br />
              This gave us the classic views of the opera house backed by the habour bridge.</p>
          </div>


          <div className="photoset-row">
            <div className="photoset-item three">
              <picture data-name="4">
                <source srcSet="/sydney/thumbnail/4.webp, /sydney/thumbnail/4-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/4.jpg, /sydney/thumbnail/4-2x.jpg 2x" />
                <img src="/sydney/thumbnail/4.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item seven">
              <picture data-name="6">
                <source srcSet="/sydney/thumbnail/6.webp, /sydney/thumbnail/6-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/6.jpg, /sydney/thumbnail/6-2x.jpg 2x" />
                <img src="/sydney/thumbnail/6.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item seven">
              <picture data-name="9">
                <source srcSet="/sydney/thumbnail/9.webp, /sydney/thumbnail/9-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/9.jpg, /sydney/thumbnail/9-2x.jpg 2x" />
                <img src="/sydney/thumbnail/9.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item three">
              <picture data-name="2">
                <source srcSet="/sydney/thumbnail/2.webp, /sydney/thumbnail/2-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/2.jpg, /sydney/thumbnail/2-2x.jpg 2x" />
                <img src="/sydney/thumbnail/2.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="8">
                <source srcSet="/sydney/thumbnail/8.webp, /sydney/thumbnail/8-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/8.jpg, /sydney/thumbnail/8-2x.jpg 2x" />
                <img src="/sydney/thumbnail/8.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="7">
                <source srcSet="/sydney/thumbnail/7.webp, /sydney/thumbnail/7-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/7.jpg, /sydney/thumbnail/7-2x.jpg 2x" />
                <img src="/sydney/thumbnail/7.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item seven">
              <picture data-name="54">
                <source srcSet="/sydney/thumbnail/54.webp, /sydney/thumbnail/54-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/54.jpg, /sydney/thumbnail/54-2x.jpg 2x" />
                <img src="/sydney/thumbnail/54.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item three">
              <picture data-name="10">
                <source srcSet="/sydney/thumbnail/10.webp, /sydney/thumbnail/10-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/10.jpg, /sydney/thumbnail/10-2x.jpg 2x" />
                <img src="/sydney/thumbnail/10.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="53">
                <source srcSet="/sydney/thumbnail/53.webp, /sydney/thumbnail/53-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/53.jpg, /sydney/thumbnail/53-2x.jpg 2x" />
                <img src="/sydney/thumbnail/53.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">

              <picture data-name="11">
                <source srcSet="/sydney/thumbnail/11.webp, /sydney/thumbnail/11-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/11.jpg, /sydney/thumbnail/11-2x.jpg 2x" />
                <img src="/sydney/thumbnail/11.jpg" alt="Kiwi cachuate images" />
              </picture>

            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six">
              <picture data-name="12">
                <source srcSet="/sydney/thumbnail/12.webp, /sydney/thumbnail/12-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/12.jpg, /sydney/thumbnail/12-2x.jpg 2x" />
                <img src="/sydney/thumbnail/12.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item four">
              <picture data-name="14">
                <source srcSet="/sydney/thumbnail/14.webp, /sydney/thumbnail/14-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/14.jpg, /sydney/thumbnail/14-2x.jpg 2x" />
                <img src="/sydney/thumbnail/14.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item four">
              <picture data-name="42">
                <source srcSet="/sydney/thumbnail/42.webp, /sydney/thumbnail/42-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/42.jpg, /sydney/thumbnail/42-2x.jpg 2x" />
                <img src="/sydney/thumbnail/42.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six">
              <picture data-name="43">
                <source srcSet="/sydney/thumbnail/43.webp, /sydney/thumbnail/43-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/43.jpg, /sydney/thumbnail/43-2x.jpg 2x" />
                <img src="/sydney/thumbnail/43.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five"><img data-layzr="sydney/thumbnail/15.jpg" data-layzr-retina="sydney/thumbnail/15-2x.jpg" data-layzr-webp="sydney/thumbnail/15.webp" data-layzr-retina-webp="sydney/thumbnail/15-2x.webp" data-name="15" /></div>
            <div className="photoset-item five"><img data-layzr="sydney/thumbnail/16.jpg" data-layzr-retina="sydney/thumbnail/16-2x.jpg" data-layzr-webp="sydney/thumbnail/16.webp" data-layzr-retina-webp="sydney/thumbnail/16-2x.webp" data-name="16" /></div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="18">
                <source srcSet="/sydney/thumbnail/18.webp, /sydney/thumbnail/18-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/18.jpg, /sydney/thumbnail/18-2x.jpg 2x" />
                <img src="/sydney/thumbnail/18.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="17">
                <source srcSet="/sydney/thumbnail/17.webp, /sydney/thumbnail/17-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/17.jpg, /sydney/thumbnail/17-2x.jpg 2x" />
                <img src="/sydney/thumbnail/17.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="20">
                <source srcSet="/sydney/thumbnail/20.webp, /sydney/thumbnail/20-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/20.jpg, /sydney/thumbnail/20-2x.jpg 2x" />
                <img src="/sydney/thumbnail/20.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="19">
                <source srcSet="/sydney/thumbnail/19.webp, /sydney/thumbnail/19-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/19.jpg, /sydney/thumbnail/19-2x.jpg 2x" />
                <img src="/sydney/thumbnail/19.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="22">
                <source srcSet="/sydney/thumbnail/22.webp, /sydney/thumbnail/22-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/22.jpg, /sydney/thumbnail/22-2x.jpg 2x" />
                <img src="/sydney/thumbnail/22.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="21">
                <source srcSet="/sydney/thumbnail/21.webp, /sydney/thumbnail/21-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/21.jpg, /sydney/thumbnail/21-2x.jpg 2x" />
                <img src="/sydney/thumbnail/21.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="25">
                <source srcSet="/sydney/thumbnail/25.webp, /sydney/thumbnail/25-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/25.jpg, /sydney/thumbnail/25-2x.jpg 2x" />
                <img src="/sydney/thumbnail/25.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item four">
              <picture data-name="26">
                <source srcSet="/sydney/thumbnail/26.webp, /sydney/thumbnail/26-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/26.jpg, /sydney/thumbnail/26-2x.jpg 2x" />
                <img src="/sydney/thumbnail/26.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six">
              <picture data-name="23">
                <source srcSet="/sydney/thumbnail/23.webp, /sydney/thumbnail/23-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/23.jpg, /sydney/thumbnail/23-2x.jpg 2x" />
                <img src="/sydney/thumbnail/23.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item seven"><img data-layzr="sydney/thumbnail/24.jpg" data-layzr-retina="sydney/thumbnail/24-2x.jpg" data-layzr-webp="sydney/thumbnail/24.webp" data-layzr-retina-webp="sydney/thumbnail/24-2x.webp" data-name="24" /></div>
            <div className="photoset-item three"><img data-layzr="sydney/thumbnail/29.jpg" data-layzr-retina="sydney/thumbnail/29-2x.jpg" data-layzr-webp="sydney/thumbnail/29.webp" data-layzr-retina-webp="sydney/thumbnail/29-2x.webp" data-name="29" /></div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item four">
              <picture data-name="28">
                <source srcSet="/sydney/thumbnail/28.webp, /sydney/thumbnail/28-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/28.jpg, /sydney/thumbnail/28-2x.jpg 2x" />
                <img src="/sydney/thumbnail/28.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six loaded">
              <video muted="true" loop="true" preload="true" autoPlay poster="sydney/original/bridge-tl-poster.png">
                <source src="/sydney/original/bridge-tl.mp4" type="video/mp4" />
                "Your browser does not support video streaming via HTML5."
              </video>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="49">
                <source srcSet="/sydney/thumbnail/49.webp, /sydney/thumbnail/49-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/49.jpg, /sydney/thumbnail/49-2x.jpg 2x" />
                <img src="/sydney/thumbnail/49.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item seven">
              <picture data-name="32">
                <source srcSet="/sydney/thumbnail/32.webp, /sydney/thumbnail/32-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/32.jpg, /sydney/thumbnail/32-2x.jpg 2x" />
                <img src="/sydney/thumbnail/32.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item three">
              <picture data-name="30">
                <source srcSet="/sydney/thumbnail/30.webp, /sydney/thumbnail/30-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/30.jpg, /sydney/thumbnail/30-2x.jpg 2x" />
                <img src="/sydney/thumbnail/30.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="50">
                <source srcSet="/sydney/thumbnail/50.webp, /sydney/thumbnail/50-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/50.jpg, /sydney/thumbnail/50-2x.jpg 2x" />
                <img src="/sydney/thumbnail/50.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="34">
                <source srcSet="/sydney/thumbnail/34.webp, /sydney/thumbnail/34-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/34.jpg, /sydney/thumbnail/34-2x.jpg 2x" />
                <img src="/sydney/thumbnail/34.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="36">
                <source srcSet="/sydney/thumbnail/36.webp, /sydney/thumbnail/36-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/36.jpg, /sydney/thumbnail/36-2x.jpg 2x" />
                <img src="/sydney/thumbnail/36.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item seven">
              <picture data-name="37">
                <source srcSet="/sydney/thumbnail/37.webp, /sydney/thumbnail/37-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/37.jpg, /sydney/thumbnail/37-2x.jpg 2x" />
                <img src="/sydney/thumbnail/37.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item three">
              <picture data-name="38">
                <source srcSet="/sydney/thumbnail/38.webp, /sydney/thumbnail/38-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/38.jpg, /sydney/thumbnail/38-2x.jpg 2x" />
                <img src="/sydney/thumbnail/38.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="40">
                <source srcSet="/sydney/thumbnail/40.webp, /sydney/thumbnail/40-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/40.jpg, /sydney/thumbnail/40-2x.jpg 2x" />
                <img src="/sydney/thumbnail/40.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="41">
                <source srcSet="/sydney/thumbnail/41.webp, /sydney/thumbnail/41-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/41.jpg, /sydney/thumbnail/41-2x.jpg 2x" />
                <img src="/sydney/thumbnail/41.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item six loaded">
              <video muted="true" loop="true" preload="true" autoPlay poster="sydney/original/drive-tl-poster.png">
                <source src="/sydney/original/drive-tl.mp4" type="video/mp4" />
                "Your browser does not support video streaming via HTML5."
               </video>
            </div>
            <div className="photoset-item four">
              <picture data-name="44">
                <source srcSet="/sydney/thumbnail/44.webp, /sydney/thumbnail/44-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/44.jpg, /sydney/thumbnail/44-2x.jpg 2x" />
                <img src="/sydney/thumbnail/44.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="46">
                <source srcSet="/sydney/thumbnail/46.webp, /sydney/thumbnail/46-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/46.jpg, /sydney/thumbnail/46-2x.jpg 2x" />
                <img src="/sydney/thumbnail/46.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="48">
                <source srcSet="/sydney/thumbnail/48.webp, /sydney/thumbnail/48-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/48.jpg, /sydney/thumbnail/48-2x.jpg 2x" />
                <img src="/sydney/thumbnail/48.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="47">
                <source srcSet="/sydney/thumbnail/47.webp, /sydney/thumbnail/47-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/47.jpg, /sydney/thumbnail/47-2x.jpg 2x" />
                <img src="/sydney/thumbnail/47.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item four">
              <picture data-name="45">
                <source srcSet="/sydney/thumbnail/45.webp, /sydney/thumbnail/45-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/45.jpg, /sydney/thumbnail/45-2x.jpg 2x" />
                <img src="/sydney/thumbnail/45.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item six">
              <picture data-name="5">
                <source srcSet="/sydney/thumbnail/5.webp, /sydney/thumbnail/5-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/5.jpg, /sydney/thumbnail/5-2x.jpg 2x" />
                <img src="/sydney/thumbnail/5.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="52">
                <source srcSet="/sydney/thumbnail/52.webp, /sydney/thumbnail/52-2x.webp 2x" />
                <source srcSet="/sydney/thumbnail/52.jpg, /sydney/thumbnail/52-2x.jpg 2x" />
                <img src="/sydney/thumbnail/52.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
    
